export default defineNuxtRouteMiddleware((_to, _from) => {
  const { loggedIn } = storeToRefs(useAuthStore());
  const { userData } = storeToRefs(useUserStore());

  if (!loggedIn.value) {
    return navigateTo('/login');
  }

  if (!userData.value.isEmailVerified) {
    return navigateTo('/verifying-email');
  }
});
